import { DateTime, Duration } from 'luxon';
import { pluralize } from './stringUtils';

export function formatRelativePastDateTime(dateTime: DateTime) {
  const { minutes, hours, days, years } = DateTime.local().diff(dateTime, [
    'days',
    'hours',
    'minutes',
    'years',
  ]);
  if (minutes < 1 && hours === 0 && days === 0 && years === 0) {
    return 'just now';
  }
  if (minutes < 60 && hours === 0 && days === 0 && years === 0) {
    return `${Math.floor(minutes)} min`;
  }
  if (hours <= 12 && days === 0 && years === 0) {
    return `${Math.floor(hours)} hr`;
  }
  if (hours <= 24 && days === 0 && years === 0) {
    return dateTime.toFormat('h:mm a');
  }
  if (days < 7 && years === 0) {
    return dateTime.toFormat('ccc');
  }
  if (days >= 7 && years === 0) {
    return dateTime.toFormat('MMM d');
  }
  return dateTime.toFormat('MMM d, yyyy');
}

export function getDateOrdinal(dateTime: DateTime) {
  const { day } = dateTime.setZone('local');
  let suffix = '';
  if (day > 3 && day < 21) {
    suffix = 'th';
  } else {
    switch (day % 10) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
        break;
    }
  }
  return day + suffix;
}

export function formatDateTimeWithOrdinalAndYear(dateTime: DateTime) {
  return `${dateTime.toFormat('ccc, LLL')} ${getDateOrdinal(dateTime)}, ${dateTime.year}`;
}

export function formatDateTimeWithOrdinal(dateTime: DateTime) {
  return `${dateTime.toFormat('ccc, LLL')} ${getDateOrdinal(dateTime)}`;
}

export function getDurationTextFromMinutes(m: number) {
  const { hours, minutes } = Duration.fromObject({ minute: m }).rescale();
  const units: string[] = [];
  if (hours) units.push(`${hours} ${pluralize('hour', hours)}`);
  if (minutes) units.push(`${minutes} ${pluralize('minute', minutes)}`);
  return units.join(' and ');
}

export const getFormattedSessionDateTimeStrings = (
  scheduledAt: DateTime,
  duration: number,
  timezone?: string,
) => {
  const scheduledAtInTimezone = scheduledAt.setZone(timezone);

  const scheduledAtDateWithOrdinalAndYear = formatDateTimeWithOrdinalAndYear(scheduledAtInTimezone);
  const scheduledAtDateWithOrdinal = formatDateTimeWithOrdinal(scheduledAtInTimezone);
  const scheduledAtStartTime = scheduledAtInTimezone.toFormat('h:mma').toLowerCase();
  const scheduledAtEndTime = scheduledAtInTimezone
    .plus({ minutes: duration })
    .toFormat('h:mma')
    .toLowerCase();
  const timezoneAbbreviation = scheduledAtInTimezone.toFormat('ZZZZ');
  return {
    scheduledAtDateWithOrdinalAndYear,
    scheduledAtDateWithOrdinal,
    scheduledAtStartTime,
    scheduledAtEndTime,
    timezoneAbbreviation,
  };
};

export function getDateStringFromParams(dateParam: string) {
  if (!dateParam) {
    return '';
  }
  const date = DateTime.fromISO(dateParam).toLocaleString(DateTime.DATE_FULL);
  return date;
}
