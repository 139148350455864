export const sentryIgnoreErrors = [
  'Failed to load Stripe.js',
  /is not a valid JavaScript MIME type/,
  /^expected expression, got/,
  /^cancelled$/,
  'NetworkError when attempting to fetch resource.',
  'Failed to fetch',
  '[NotFoundError]',
  'Request failed with status code 403',
  'Object Not Found Matching Id',
  /ResizeObserver/,
  'AbortError: The user aborted a request',
  /Importing a module script failed./,
  'Load failed',
  /dynamically imported module/,
  /Cannot get CSS styles from text's parentNode/,
  /For more details, see the full error text at/, // TODO - add id’s to graphql queries when applicable
];
