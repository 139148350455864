import { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { createContainer } from 'unstated-next';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { JoinReasonEnum } from '@motivo/guanyin/src/lookup';
import type { WhyMotivoStepFormValues } from './components/WhyMotivoStep';
import type { LicenseStepFormValues } from './components/LicenseStep';
import type { GraduatedStepFormValues } from './components/GraduatedStep';
import type { RatesStepFormValues } from './components/RatesStep';

export const STORAGE_KEY = 'motivo-questionnaire';

const ONE_WEEK_IN_DAYS = 7;

const generateExpiry = () => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + ONE_WEEK_IN_DAYS);
  return expiryDate;
};

type StoredDataType = {
  expiry: Date;
  isSubmitted: boolean;
  why: WhyMotivoStepFormValues;
  license: LicenseStepFormValues;
  graduated: GraduatedStepFormValues;
  rates: RatesStepFormValues;
};

const defaultData = {
  why: {},
  license: {},
  graduated: {},
  rates: {},
};

type SaveChangesToStoreParams = {
  isSubmitted: boolean;
} & (
  | {
      key: 'license';
      data: LicenseStepFormValues;
    }
  | {
      key: 'why';
      data: WhyMotivoStepFormValues;
    }
  | {
      key: 'graduated';
      data: GraduatedStepFormValues;
    }
  | {
      key: 'rates';
      data: RatesStepFormValues;
    }
);

const useQuestionnaireState = () => {
  const { search } = useLocation();

  const [currentStep, setCurrentStep] = useState<
    'whyMotivo' | 'license' | 'graduated' | 'rates' | 'confirmation'
  >('whyMotivo');

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const savedData: StoredDataType = JSON.parse(localStorage.getItem(STORAGE_KEY)) || defaultData;

  const [storedData, setStoredData] = useState<StoredDataType>(savedData);

  const history = useHistory();

  useEffect(() => {
    const params = queryString.parse(search);
    if (params['reset-questionnaire'] === '1') {
      setStoredData(defaultData as StoredDataType);
      localStorage.removeItem(STORAGE_KEY);
    }
  }, [search]);

  if (new Date(storedData.expiry).getTime() < Date.now()) {
    setStoredData({
      ...storedData,
      isSubmitted: false,
      expiry: generateExpiry(),
    });
    localStorage.removeItem(STORAGE_KEY);
  }

  const handleBack = () => {
    if (currentStep === 'license') {
      setCurrentStep('whyMotivo');
    }
    if (currentStep === 'graduated') {
      setCurrentStep('license');
    }
    if (currentStep === 'rates') {
      setCurrentStep('graduated');
    }
  };

  const saveChangesToStore = ({ key, data, isSubmitted = false }: SaveChangesToStoreParams) => {
    const newLocalStorageData: StoredDataType = {
      ...storedData,
      expiry: generateExpiry(),
      isSubmitted,
    };
    if (key === 'why') {
      newLocalStorageData.why = { ...data };
    }

    if (key === 'license') {
      const { isSeekingLicenseForDifferentState, ...licenseData } = data;
      newLocalStorageData.license = {
        ...licenseData,
        isSeekingLicenseForDifferentState,
        licensureUsStateId: isSeekingLicenseForDifferentState
          ? licenseData.licensureUsStateId
          : undefined,
      };
    }

    if (key === 'graduated') {
      newLocalStorageData.graduated = { ...data };
    }

    if (key === 'rates') {
      newLocalStorageData.rates = { ...data };
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(newLocalStorageData));
    setStoredData(newLocalStorageData);
  };

  const handleNext = () => {
    let joinReasonForPosthog: null | string = null;

    if (currentStep === 'whyMotivo') {
      switch (storedData.why.joinReasonId) {
        case JoinReasonEnum.LICENSURE:
          setCurrentStep('license');
          joinReasonForPosthog = 'licensure';
          break;
        case JoinReasonEnum.PEER_CONSULTATION:
          saveChangesToStore({
            key: 'why',
            data: {
              joinReasonId: JoinReasonEnum.PEER_CONSULTATION,
            },
            isSubmitted: true,
          });
          joinReasonForPosthog = 'peer_consultation';
          break;
        case JoinReasonEnum.AGENCY_PAYING_FOR_STAFF:
          joinReasonForPosthog = 'agency_paying_for_staff';
          history.push('/employer-onboarding');
          break;
        case JoinReasonEnum.SUPERVISOR:
          joinReasonForPosthog = 'supervisor';
          history.push('/supervisor-apply');
          break;
        default:
      }
    }
    if (currentStep === 'license') {
      setCurrentStep('graduated');
    }
    if (currentStep === 'graduated') {
      setCurrentStep('rates');
    }
    if (currentStep === 'rates') {
      setCurrentStep('confirmation');
    }

    posthog.capture('supervisee_onboarding:questionnaire_step_completed', {
      currentStep,
      joinReason: joinReasonForPosthog,
    });
  };

  return {
    whyData: storedData.why || ({} as WhyMotivoStepFormValues),
    licenseData: storedData.license,
    graduatedData: storedData.graduated,
    ratesData: storedData.rates || ({} as RatesStepFormValues),
    expiry: storedData.expiry,
    isSubmitted: storedData.isSubmitted,
    saveChangesToStore,
    currentStep,
    handleBack,
    handleNext,
    setCurrentStep,
  };
};

export const QuestionnaireState = createContainer(useQuestionnaireState);
